<div class="profile-container text-center">
    <h6>Welcome, {{me?.user_name}}</h6>
    <div class="profile-avatar">
        <img class="profile-avatar-image" [src]="imgBase + '/images/' + me?.user_avatar" alt="">
        <img class="avatar-star" *ngIf="me?.stars > 0" [src]="'assets/images/stars/' + me?.stars + '.png'" alt="">
        <img *ngIf="me?.achievement" class="avatar-frame" [src]="imgBase + '/' + me?.achievement?.achievement_frame" alt="">
    </div>
    <p class="winning-points text-center">Points - {{me?.user_points}}</p>
    <nb-badge [text]="'level ' + me?.level" status="success"></nb-badge>
    <div class="row">
        <div class="col-md-6">
            <div class="wallet-pills">
                <div class="pill-amolunt">{{wallet?.gxc?.wallet_balance}}</div>
                <div class="pill-text">
                    GX-Coin
                </div>
            </div>
            <p><a class="recharge" [routerLink]="['/wallet']">Recharge Now</a></p>
        </div>
        <div class="col-md-6">
            <div class="wallet-pills">
                <div class="pill-amolunt">{{wallet?.xc?.wallet_balance}}</div>
                <div class="pill-text">
                    X-Coin
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label>Friends</label>
            <div class="icon-div">
                <img src="assets/images/users.png" alt=""> {{buddies.length}}
            </div>
        </div>
        <div class="col-md-4">
            <label>Achievements</label>
            <div class="icon-div">
                <img src="assets/images/laurel-wreath-1.png" alt=""> {{myAchievements.length}}
            </div>
        </div>
        <div class="col-md-4">
            <label>Games</label>
            <div class="icon-div">
                <img src="assets/images/dice.png" alt=""> {{(playerStats?.games_won + playerStats?.games_lost)}}
            </div>
        </div>
    </div>
</div>