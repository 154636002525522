import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  invData: any = [];
  categories: any = [];
  isShowBottomCart: boolean = false;
  _backBtnClick: number = 0;

  private gameDone = new BehaviorSubject<Boolean>(false);
  gameDoneChange = this.gameDone.asObservable();

  constructor() { }

  _gameDone(active){
    this.gameDone.next(active);
  }

}
