import { Component } from '@angular/core';
import { ApiService } from "../../../_services/apiService";
import { Toster } from "../../../_services/toster";
import { Validators } from "../../../_services/validators";

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent {

  constructor(
    private api: ApiService,
    private toster: Toster,
    private validator: Validators
  ) {
  }

  footerEmail
  footerName

  sendGetInTouch = async () => {
    if (!this.validator.emailValidator(this.footerEmail)) {
      this.toster.errorToster('ERROR', 'Please Enter Valid Email Address!')
    } else if (!this.footerName) {
      this.toster.errorToster('ERROR', 'Please Enter Name!')
    } else {
      let data = {
        name: this.footerName,
        email: this.footerEmail
      }
      let res = await this.api.getInTouth(data);
      if (res['code'] == 200) {
        this.toster.sucessToster('SUCCESS', 'Record Submited Successfully!');
        this.footerEmail = '';
        this.footerName = '';
      } else {
        this.toster.errorToster('ERROR', 'Something Went Wrong!')
      }
    }
  }

}
