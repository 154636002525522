<div class="header-container">
  <a class="" routerLink="/">
    <img src="assets/images/logo.png" class="logo-header" alt="">
  </a>
</div>

<nb-sidebar fixed left state="collapsed">
  <div class="pages-link-container">
    <div class="page-link-container">
      <a class="page-link mob-res-header-links" routerLink="/aboutus">About Us</a>
    </div>
    <div class="page-link-container">
      <a class="page-link mob-res-header-links" routerLink="/parents">For Parents</a>
    </div>
    <div class="page-link-container">
      <a class="page-link mob-res-header-links" routerLink="/fortutor">For Tutors</a>
    </div>
    <div class="page-link-container">
      <a class="page-link mob-res-header-links" routerLink="/institution">For Institutions</a>
    </div>
    <div class="page-link-container">
      <a class="page-link mob-res-header-links" routerLink="/prices">Pricing</a>
    </div>
    <div class="page-link-container">
      <a class="page-link mob-res-header-links" routerLink="/contactus">Contact Us</a>
    </div>
    <div class="page-link-container">
      <a class="page-link mob-res-header-links" routerLink="/findtutor">Find Tutor</a>
    </div>
    <div class="page-link-container">
      <a class="page-link mob-res-header-links" routerLink="/auth/login">Login/Signup</a>
    </div>
  </div>
</nb-sidebar>

<div class="header-container">
  <div *ngIf="!isMobile" class="pages-link-container">
    <div class="page-link-container">
      <a class="page-link" routerLink="/aboutus">About Us</a>
    </div>
    <div class="page-link-container">
      <a class="page-link" routerLink="/parents">For Parents</a>
    </div>
    <div class="page-link-container">
      <a class="page-link" routerLink="/fortutor">For Tutors</a>
    </div>
    <div class="page-link-container">
      <a class="page-link" routerLink="/institution">For Institutions</a>
    </div>
    <div class="page-link-container">
      <a class="page-link" routerLink="/prices">Pricing</a>
    </div>
    <div class="page-link-container">
      <a class="page-link" routerLink="/contactus">Contact Us</a>
    </div>
    <div class="page-link-container">
      <a class="page-link" routerLink="/findtutor">Find Tutor</a>
    </div>
  </div>

  <div *ngIf="isMobile" class="mobile-hamburger-container">
    <button (click)="toggle()" class="ham-icon-header"><img src="../../../../assets/images/hamBurgerIcon.png"
        alt=""></button>
  </div>

  <!-- <button *ngIf="currentUser" class="login-button" routerLink="/" (click)="logout()">Logout</button> -->
  <div [nbPopover]="templateRef" *ngIf="currentUser" class="after-login-div">
    <img [src]="profileImage" class="profile-image" alt="">
  </div>
  <ng-template #templateRef>
    <div class="profile-links-container">
      <div class="profile-link-container">
        <a class="profile-link" style="cursor: pointer;" (click)="goToProfile()">Your Profile</a>
      </div>
      <div class="profile-link-container">
        <a class="profile-link" style="cursor: pointer;" (click)="goToDashboard()">Dashboard</a>
      </div>
      <div class="profile-link-container">
        <a class="profile-link" style="cursor: pointer;" routerLink="/" (click)="logout()">Logout</a>
      </div>
    </div>
  </ng-template>
  <button *ngIf="!currentUser && !isMobile" class="button-animate login-button" routerLink="/auth/login">Sign Up/Login</button>
</div>