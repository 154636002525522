<div class="footer-container">
    <div class="details">
        <img class="footer-logo" src="../../../../assets/images/logo.png" alt="">
        <div class="add-container">
            <p class="address">Headquartered in NYC</p>
            <p class="address">Worldwide Tutor Network</p>
            <p class="address">Get in Touch</p>
            <p class="address">info@kusaed.com</p>
        </div>
        <div class="social-container">
            <h3 class="heading">Follow On</h3>
            <div class="social-image-container">
                <a href="#"><img class="social-site" src="../../../../assets/images/facebook.png" alt=""></a>
                <a href="#"><img class="social-site" src="../../../../assets/images/insta.png" alt=""></a>
                <a href="#"><img class="social-site" src="../../../../assets/images/twitter.png" alt=""></a>
            </div>
        </div>
    </div>
    <div class="links">
        <div class="row">
            <div class="col">
                <h3 class="links-heading">Useful Links</h3>
                <div class="links-container">
                    <div class="link-margin-top"><a class="link" routerLink="/fortutor">Become a Tutor</a></div>
                    <div class="link-margin-top"><a class="link" routerLink="/aboutus">About Us</a></div>
                    <div class="link-margin-top"><a class="link" routerLink="/resources">Free resources</a></div>
                    <div class="link-margin-top"><a class="link" routerLink="/prices">Prices</a></div>
                    <div class="link-margin-top"><a class="link" routerLink="/contactus">Contact Us</a></div>
                </div>
            </div>
            <div class="col">
                <h3 class="links-heading">High School</h3>
                <div class="links-container">
                    <div class="link-margin-top"><a class="link" href="/">Subject Tutoring</a></div>
                    <div class="link-margin-top"><a class="link" href="/">SAT/ACT</a></div>
                    <div class="link-margin-top"><a class="link" href="/">College Admission</a></div>
                </div>
            </div>
            <div class="col">
                <h3 class="links-heading">Grade 3-8</h3>
                <div class="links-container">
                    <div class="link-margin-top"><a class="link" href="/">ELA</a></div>
                    <div class="link-margin-top"><a class="link" href="/">MATH</a></div>
                    <div class="link-margin-top"><a class="link" href="/">STEM</a></div>
                </div>
            </div>
        </div>
    </div>
    <div class="form">
        <h3 class="form-heading">Get In Touch With Us!</h3>
        <div class="form-container">
            <input placeholder="Name" class="input-text text-field-email" type="text" name="first-name" [(ngModel)]="footerName"> 
            <input placeholder="Email" class="input-text text-field-margin-top" type="text" name="first-name" [(ngModel)]="footerEmail"> 
            <button class="submit-button-footer button-animate" (click)="sendGetInTouch()">Submit</button>
        </div>
    </div>
</div>
<div class="copy-content">
    <p class="links secondary-color">© 2020 By Early Bird Students LLC. All Rights Reserved</p>
    <div>
        <a href="" class="links terms">Terms and Conditions Of Use</a>
        <a href="" class="links">Privacy Policy</a>
    </div>
</div>