import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { AuthenticationLayoutComponent } from './layouts/authentication-layout/authentication-layout.component';
import { AuthGuard } from "./services/auth.guard";

const routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: '',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./layouts/dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule)
      }
    ]
  },
  {
    path: 'auth',
    component: AuthenticationLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/authentication-layout/authentication-layout.module').then(m => m.AuthenticationLayoutModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

export const AppRoutingModule : Routes = routes;