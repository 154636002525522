import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs/internal/Subject';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  base_url:string;
  cookie:string;
  cookieKey:string;
  url: any = "http://localhost:4200";
  ws_url: any;
  img_url: any;
  private messageSource = new Subject();
  currentMessage = this.messageSource.asObservable();


  constructor(private router: Router, private http: HttpClient, private _cookieService:CookieService) {

  // this.base_url = "http://localhost:3000/api";
  // this.ws_url = "http://localhost:3000";
  // this.img_url = "http://localhost:3000";
  // this.url = "http://localhost:4200";

  this.base_url = "https://api.goflyx.ml/api";
  this.ws_url = "https://api.goflyx.ml";
  this.img_url = "https://api.goflyx.ml";
  this.url = "https://goflyx-game-ui.vercel.app";

  this.cookie = this.getCookie();
  this.cookieKey = "__g0fx";

  }

  getUrl(){
    return this.url;
  }

  getCookie(key = this.cookieKey){
    return this._cookieService.get(key);
  }

  setCookie(value, key = this.cookieKey){
    return this._cookieService.put(key, value);
  }

  removeCookie(key = this.cookieKey){
    return this._cookieService.remove(key);
  }

  logout(){
    this.removeCookie();
    this.removeCookie("__main");
    this.removeCookie("__id");
    this.router.navigate(['/auth/login']);
  }

  getBaseUrl(){
    return this.base_url;
  }

  getWsUrl(){
    return this.ws_url;
  }

  getImageBase(){
    return this.img_url;
  }

  getAuth(){
    let headerOption = new HttpHeaders ();

    let login = this.getCookie();

    if(login === undefined){
      return false;
    }else{
      return headerOption.append('Authorization', 'Bearer ' + this.getCookie());
    }
  }

  getHeaderAuth(){
    let headerOption = new HttpHeaders ();
    return headerOption.append('Authorization', 'Bearer ' + this.getCookie());
  }
  
  login(obj){
    return this.http.post('https://gfx.rendrr.net/index.php/auth/login', obj);
  }

  register(obj){
    return this.http.post('https://gfx.rendrr.net/index.php/auth/register_on_light_box', obj);
  }
  
  loginToPortal(user){
    return this.http.post(this.base_url+'/user/login', user);
  }

  registerToPortal(user){
    return this.http.post(this.base_url+'/user/register', user);
  }

  getUpcoming(){
    return this.http.get(this.base_url+'/game');
  }

  getGame(id){
    return this.http.get(this.base_url+'/game/i/'+id);
  }

  getFinished(query){
    return this.http.post(this.base_url+'/game/finished', query);
  }

  loadWallets(){
      return this.http.get(this.base_url+'/wallet', { headers: this.getHeaderAuth() });
  }

  getWallet(_type){
      return this.http.get(this.base_url+'/wallet/t/' + _type, { headers: this.getHeaderAuth() });
  }

  getStats(){
      return this.http.get(this.base_url+'/game/my/report', { headers: this.getHeaderAuth() });
  }

  getLatestGames(){
      return this.http.get(this.base_url+'/game/my/games', { headers: this.getHeaderAuth() });
  }

  rechargeWallet(obj){
    return this.http.post(this.base_url+'/wallet/add', obj, { headers: this.getHeaderAuth() });
  }

  rechargeXcWallet(obj){
    return this.http.post(this.base_url+'/wallet/add/xc', obj, { headers: this.getHeaderAuth() });
  }

  redeemWallet(obj){
    return this.http.post(this.base_url+'/wallet/redeem', obj, { headers: this.getHeaderAuth() });
  }

  buddies(){
    return this.http.get(this.base_url+'/user/buddies', { headers: this.getHeaderAuth() });
  }

  me(){
    return this.http.get(this.base_url+'/user/me', { headers: this.getHeaderAuth() });
  }

  createOrder(obj) {
    return this.http.post(this.base_url+'/order', obj, { headers: this.getHeaderAuth() });
  }

  getAllAchievements() {
    return this.http.get(this.base_url+'/achievement', { headers: this.getHeaderAuth() });
  }

  getAchievements() {
    return this.http.get(this.base_url+'/achievement/me', { headers: this.getHeaderAuth() });
  }

  walletTransfer(obj) {
    return this.http.post(this.base_url+'/wallet/transfer', obj, { headers: this.getHeaderAuth() });
  }
}