import { Injectable, OnInit } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Validators implements OnInit {

    constructor() {
    }


    ngOnInit() { }

    emailValidator(email: any) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    psswordValidator(string) {
        if (!string) {
            return false;
        } else {
            if (string.lengh < 6) {
                return false;
            } else {
                return true;
            }
        }
    }

    dateFormat = (date: any) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    timeFormat = (val: any) => {
        var date = new Date(val),
            hr = '' + date.getHours(),
            min = '' + date.getMinutes();

        if (hr.length < 2)
            hr = '0' + hr;
        if (min.length < 2)
            min = '0' + min;

        return [hr, min].join('-');
    }

    isMobile = () => {
        var ua = navigator.userAgent;
        if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
            return true
        else
            return false
    }

    chekZeroOnTime = (i: any) => {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

}
