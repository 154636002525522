import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../_services/authService';
import { Validators } from "../../../_services/validators";
import { Router } from '@angular/router';
import { NbSidebarService } from '@nebular/theme';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  currentUser
  isMobile

  constructor(
    private validator: Validators,
    private authService: AuthService,
    private router: Router,
    private sidebarService: NbSidebarService
  ) {
  }

  profileImage

  ngOnInit(): void {
    // console.log("check mobile is", this.validator.isMobile())
    this.isMobile = this.validator.isMobile();

    this.currentUser = this.authService.checkLogin();
    this.profileImage = this.authService.getProfileImage();
  }

  logout = () => {
    this.currentUser = this.authService.logout();
  }

  goToProfile = () => {
    if (this.authService.getUserRole() == "STUDENT" || this.authService.getUserRole() == "PARENT" || this.authService.getUserRole() == "INSTITUTION") {
      this.router.navigateByUrl("/student-profile");
    } else if (this.authService.getUserRole() == "TUTOR") {
      this.router.navigateByUrl("/tutor-profile");
    }
  }

  goToDashboard = () => {
    if (this.authService.getUserRole() == "STUDENT" || this.authService.getUserRole() == "PARENT" || this.authService.getUserRole() == "INSTITUTION") {
      this.router.navigateByUrl("/student-dashboard");
    } else if (this.authService.getUserRole() == "TUTOR") {
      this.router.navigateByUrl("/tutor-dashboard");
    }
  }

  toggle() {
    this.sidebarService.toggle();
  }

}
