import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpUrlEncodingCodec } from "@angular/common/http";
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ApiService implements OnInit {

    currentUser;
    dialogRef: any;
    headers;

    constructor(
        private httpClient: HttpClient,
        private router: Router
    ) {
    }


    ngOnInit() {
    }
    sociallogini(data) {

        this.headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
        });
        const params = new HttpParams()
            .set('first_name', data.first_name)
            .set('last_name', data.last_name)
            .set('email', data.email)
            .set('role', data.role)
            .set('step', data.step)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/socal_login`, params)
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                        console.log(response);
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })


    }

    signup(data) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
        });
        const params = new HttpParams()
            .set('first_name', data.first_name)
            .set('last_name', data.last_name)
            .set('email', data.email)
            .set('role', data.role)
            .set('password', data.password)
            .set('step', data.step)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/sign_up`, params)
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                        console.log(response);
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    login(data) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
        });
        const params = new HttpParams()
            .set('email', data.email)
            .set('password', data.password)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/login`, params)
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    updateUserDetails(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('token', this.currentUser['token'])
            .set('Content-Type', 'application/x-www-form-urlencoded')

        let params
        if (this.currentUser['user_role'] == 'STUDENT' || this.currentUser['user_role'] == 'PARENT' || this.currentUser['user_role'] == 'INSTITUTION') {
            params = new HttpParams()
                .set('user_id', this.currentUser['user_id'])
                .set('address', data.address)
                .set('country', data.country)
                .set('state', data.state)
                .set('city', data.city)
                .set('zipCode', data.zipCode)
                .set('phone', data.phone)
                .set('step', data.step)
                .set('profile_pic', data.profile_image)
                .set('institute_name', data.institute_name)
            // .set('subject[0][subject_id]', '61307948eabbf03be42d2ed2')
            // .set('grade_level[0][grade_id]', '61307975eabbf03be42d2ee4')
            data.subjects.map((e, i) => {
                console.log("the element is", e)
                params = params.append(`subject[${i}][subject_id]`, e)
            });

            data.grades.map((e, i) => {
                params = params.append(`grade_level[${i}][grade_id]`, e)
            });

        } else if (this.currentUser['user_role'] == 'TUTOR') {
            if (data.step == 2) {
                params = new HttpParams()
                    .set('user_id', this.currentUser['user_id'])
                    .set('address', data.address)
                    .set('country', data.country)
                    .set('state', data.state)
                    .set('city', data.city)
                    .set('zipCode', data.zipCode)
                    .set('phone', data.phone)
                    .set('step', data.step)
                    .set('bio', data.bio)
                    .set('profile_pic', data.profile_image)
                    .set('institute_name', data.institute_name)
                data.subjects.map((e, i) => {
                    console.log("the element is", e)
                    params = params.append(`subject[${i}][subject_id]`, e)
                });

                data.grades.map((e, i) => {
                    params = params.append(`grade_level[${i}][grade_id]`, e)
                });

            } else if (data.step == 3) {
                params = new HttpParams()
                    .set('user_id', this.currentUser['user_id'])
                    .set('educational_background[certified]', data.certified)
                    .set('educational_background[major]', data.major)
                    .set('educational_background[institute_name]', data.edu_institute_name)
                    .set('educational_background[year]', data.edu_year)
                    .set('professional_background[title]', data.pro_title)
                    .set('professional_background[institute_name]', data.pro_institute_name)
                    .set('professional_background[experience]', data.pro_experience)
                    .set('step', data.step)
            } else {

            }
        } else {

        }
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/complete_sign_up`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    getTutors(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        let params = new HttpParams()

        if (this.currentUser) {
            params = params.append("student_id", this.currentUser['user_id'])
            // params = params.append('authorization', this.currentUser['token'])
        }
        if (data.tutor_name) {
            params = params.append("tutor_name", data.tutor_name)
            // params = params.append('authorization', this.currentUser['token'])
        }

        if (data.subjects.length > 0) {
            data.subjects.map((e, i) => {
                params = params.append(`subject[${i + 1}][subject_id]`, `${e}`)
            });
        }

        if (data.grades.length > 0) {
            data.grades.map((e, i) => {
                params = params.append(`grade_level[${i + 1}][grade_id]`, e)
            });
        }
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/tutor_list_filter`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    requestSession(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('authorization', this.currentUser['token'])
            .set('Content-Type', 'application/x-www-form-urlencoded')
        const params = new HttpParams()
            .set('student_id', this.currentUser['user_id'])
            .set('tutor_id', data.tutor_id)
            .set('select_date', data.select_date)
            // .set('select_time', data.select_time)
            // .set('slot_time', data.slot_time)
            .set('plateform', data.plateform)
            .set('subject_id', data.subject_id)
            .set('grade_id', data.grade_id)
            .set('start_time', data.start_time)
            .set('end_time', data.end_time)
            .set('timeslot_id', data.timeslot_id)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/request_session`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    acceptSession(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        let user_type;
        if (this.currentUser['user_role'] == 'STUDENT') {
            user_type = 1
        } else if (this.currentUser['user_role'] == 'PARENT') {
            user_type = 2
        } else {
            user_type = 3
        }
        this.headers = new HttpHeaders()
            .set('authorization', this.currentUser['token'])
            .set('Content-Type', 'application/x-www-form-urlencoded')
        var params = new HttpParams()
            .set('tutor_id', this.currentUser['user_id'])
            .set('session_id', data.session_id)
            .set('topic', data.topic)
            .set('request_type', user_type)
            .set('schedule', '4')
            .set('start_time', data.start_time)
            .set('end_time', data.end_time)
            .set('time_zone', data.time_zone)
        if (data.other_link) {
            params = params.append(`other_link`, data.other_link)
        }

        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/accept_session`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    cancelSession(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        let user_type;
        if (this.currentUser['user_role'] == 'STUDENT') {
            user_type = 1
        } else if (this.currentUser['user_role'] == 'PARENT') {
            user_type = 2
        } else {
            user_type = 3
        }
        this.headers = new HttpHeaders()
            .set('authorization', this.currentUser['token'])
            .set('Content-Type', 'application/x-www-form-urlencoded')
        var params = new HttpParams()
            .set('tutor_id', this.currentUser['user_id'])
            .set('session_id', data.session_id)
            .set('topic', data.topic)
            .set('request_type', user_type)
            .set('schedule', '2')
        if (data.other_link) {
            params = params.append(`other_link`, data.other_link)
        }

        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/accept_session`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    getSessionsList(status) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('authorization', this.currentUser['token'])
            .set('Content-Type', 'application/x-www-form-urlencoded')
        const params = new HttpParams()
            .set('User_id', this.currentUser['user_id'])
            .set('UserType', this.currentUser['user_role'])
            .set('Status', status)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/user_session_list`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    getPakage() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        return new Promise((resolve, reject) => {
            this.httpClient.get(`${environment.APIURL}/user/get_package`, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    applyDiscount(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('authorization', this.currentUser['token'])
            .set('Content-Type', 'application/x-www-form-urlencoded')
        const params = new HttpParams()
            .set('user_id', this.currentUser['user_id'])
            .set('package_id', data.package_id)
            .set('promo_code', data.promo_code)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/apply_promo`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["status"]) {
                        resolve(response)
                    } else {
                        reject(response)
                    }
                });
        })
    }

    buypakage(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('authorization', this.currentUser['token'])
            .set('Content-Type', 'application/x-www-form-urlencoded')
        const params = new HttpParams()
            .set('student_id', this.currentUser['user_id'])
            .set('package_id', data.package_id)
            .set('amount', data.amount)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/buy_package`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    getGradeSubjects(key) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        const params = new HttpParams()
            .set('key', key)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/get_grade_subjects`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    getTutorProfile(user_id) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        var params = new HttpParams()
            .set('user_id', user_id)
            .set('role', 'TUTOR')
        if (this.currentUser != undefined || this.currentUser != null) {
            params = params.append("student_id", this.currentUser['user_id'])
        }
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/get_profile`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    getStudentProfile() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        var params = new HttpParams()
            .set('student_id', this.currentUser['user_id'])
            .set('role', 'STUDENT')
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/get_profile`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    getSessionLeft() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        var params = new HttpParams()
            .set('user_id', this.currentUser['user_id'])
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/sesion_count`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }
    getAvilibity(data) {
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        var params = new HttpParams()
            .set('tutor_id', data.tutor_id)
            .set('day', data.day)
            .set('time', data.time)
            .set('date', data.date)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/get_available_time`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }
    updateStudentProfile(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        var params = new HttpParams()
            .set('user_id', this.currentUser['user_id'])
            .set('email', data.email)
            .set('parent_name', data.parent_name)
            .set('school_name', data.school_name)
            .set('school_year', data.school_year)
            .set('address', data.address)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/edit_profile`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    updateTutorProfile(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        var params = new HttpParams()
            .set('user_id', this.currentUser['user_id'])
            .set('email', data.email)
            .set('parent_name', data.parent_name)

            .set('address', data.address_details.address)
            .set('state', data.address_details.state)
            .set('city', data.address_details.city)
            .set('zipCode', data.address_details.zipCode)
            .set('country', data.address_details.country)

            .set('educational_background[major]', data.educational.major)
            .set('educational_background[institute_name]', data.educational.name)
            .set('educational_background[year]', data.educational.year)

            .set('professional_background[title]', data.professional.title)
            .set('professional_background[institute_name]', data.professional.name)
            .set('professional_background[experience]', data.professional.experience)

        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/edit_profile`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    getInTouth(data) {
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        const params = new HttpParams()
            .set('name', data.name)
            .set('email', data.email)
            .set('comments', 'No Comments')
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/contact_us`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    consultation(data) {
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        const params = new HttpParams()
            .set('user_type', data.user_type)
            .set('first_name', data.first_name)
            .set('last_name', data.last_name)
            .set('email', data.email)
            .set('subject', data.subject)
            .set('grade', data.grade)
            .set('additional_info', data.additional_info)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/consultation`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    addFav(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        const params = new HttpParams()
            .set('student_id', this.currentUser['user_id'])
            .set('tutor_id', data.tutor_id)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/add_remove_wishlist`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    removedFav(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        const params = new HttpParams()
            .set('student_id', this.currentUser['user_id'])
            .set('tutor_id', data.tutor_id)
            .set('wishlist_id', data.wishlist_id)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/add_remove_wishlist`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })

    }

    intitutionForm(data) {
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        const params = new HttpParams()
            .set('first_name', data.first_name)
            .set('last_name', data.last_name)
            .set('email', data.email)
            .set('phone_no', data.phone)
            .set('institution_name', data.district)
            .set('desc', data.qurey)
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/institution_form`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    getFreeResources(data) {
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        var params = new HttpParams()
            .set('page', data.page)
            .set('limit', data.limit)
        if (data.subject) {
            params = params.append(`subjectId`, data.subject)
        }
        if (data.grade) {
            params = params.append(`gradeId`, data.grade)
        }
        if (data.search) {
            params = params.append(`search_resource`, data.search)
        }
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/free_resources_list`, params, { headers: this.headers })
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    uploadImageGetUrl(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));

        const options = {
            headers: new HttpHeaders().append("Content-Type", "application/x-www-form-urlencoded"),
            params: new HttpParams().append("Accept", "application/json")
        }

        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append("user_id", this.currentUser['user_id']);
        urlSearchParams.append("base64", data);
        let body = urlSearchParams.toString()

        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/media_upload`, body, options)
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    uploadTutorMedia(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));

        let body = {
            user_id: this.currentUser['user_id'],
            media: data,
            step: "4"
        }

        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/media_save`, body)
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    updateUserPicture(data) {
        this.currentUser = JSON.parse(localStorage.getItem('user'));

        let body = {
            user_id: this.currentUser['user_id'],
            base64: data
        }

        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/image_update`, body)
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    updateTutorAvaibility = (data) => {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        let body = {
            tutor_id: this.currentUser['user_id'],
            slot: data
        }
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/create_available_slot`, body)
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    addTimeAvailibility = (data) => {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        let body = {
            tutor_id: this.currentUser['user_id'],
            start_time: data.start_time,
            end_time: data.end_time
        }
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/add_tutor_time_availability`, body)
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    removeTimeAvailibility = (data) => {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        let body = {
            tutor_id: this.currentUser['user_id'],
            start_time: data.start_time,
            end_time: data.end_time
        }
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/remove_tutor_time_availability`, body)
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    getTimeAvailibility = (tutorId) => {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        let body = {
            tutor_id: tutorId ? tutorId : this.currentUser['user_id'],
        }
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/get_tutor_time_availability`, body)
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }

    getAvailableTimeSlotsDateWise = (data) => {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        let body = {
            tutor_id: data.tutor_id,
            start_time: data.start_time,
            end_time: data.end_time
        }
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${environment.APIURL}/user/get_tutor_available_time_slot_datewise`, body)
                .subscribe(response => {
                    if (response["code"]) {
                        resolve(response)
                    } else {
                        console.log("failed to fetch");
                        reject(response)
                    }
                });
        })
    }


}
