import { Injectable, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class Toster {

    constructor(private toster: ToastrService) {
    }

    tosterBottomCenterPosition = "toast-bottom-center";
    tosterTimeout = 3000;

    tosterOptions = {
        positionClass: this.tosterBottomCenterPosition,
        tapToDismiss: true,
        closeButton: true,
        timeOut: this.tosterTimeout
    }

    sucessToster(title, message) {
        this.toster.success(message, title, this.tosterOptions);
    }

    errorToster(title, message) {
        this.toster.error(message, title, this.tosterOptions);
    }

    waringToster(title, message) {
        this.toster.warning(message, title, this.tosterOptions);
    }

}
