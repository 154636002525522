import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { DataService } from "../../services/data.service";

@Component({
  selector: 'nav-profile',
  templateUrl: './nav-profile.component.html',
  styleUrls: ['./nav-profile.component.scss']
})
export class NavProfileComponent implements OnInit {
  me: any = null;
  wallet: any = {
    gxc: null,
    xc: null,
    wgxc: null,
    bgxc: null,
    xcp: 0
  };
  buddies: any = [];
  playerStats: any = null;
  imgBase: any = null;
  myAchievements: any = [];

  constructor(private authService: AuthService, private dataService: DataService) {
    this.imgBase = this.authService.getImageBase();
  }

  ngOnInit(): void {
    this.loadData();
    this.dataService.gameDoneChange.subscribe(data => {
      if(data) this.loadData();
    })
  }

  loadData(){
    this.authService.me().subscribe(data => {
      if(data['status']){
        this.me = data['data'];
      }
    });

    this.authService.loadWallets().subscribe(res => {
      if(res['status']){
        let data = res['data'];
        for (const [key, value] of Object.entries(this.wallet)) {
          this.wallet[key] = data.find(ele => ele.wallet_type == key);
        }
      }
    })
    
    this.authService.buddies().subscribe(data => {
      if(data['status']){
        this.buddies = data['data'];
      }
    }, error => {})

    this.authService.getStats().subscribe(data => {
      if(data['status']){
        this.playerStats = data['data'][0];
      }
    })

    this.authService.getAchievements().subscribe(data1 => {
      if(data1['status']) {
        this.myAchievements = data1['data'].map(ele => ele.achievement);
      }
    })

  }

}
