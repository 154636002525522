import { Injectable, OnInit } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnInit {

    constructor() {
    }

    currentUser;


    ngOnInit() { }

    checkLogin = () => {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        return this.currentUser;
    }

    logout = () => {
        this.currentUser = localStorage.removeItem('user');
        return this.currentUser;
    }

    getUserRole = () => {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        if (this.currentUser) {
            return this.currentUser['user_role'];
        } else {
            return false
        }
    }

    getProfileImage = () => {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        let userProfile = this.currentUser['profile_pic']
        if (!userProfile || userProfile == 'undefined') {
            return "/assets/images/usersample.png"
        } else {
            return this.currentUser['profile_pic']
        }
    }

}
